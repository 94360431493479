.container {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.rmpButtonContainer {
    background-color: rgba(33, 33, 33, 0.85);
    opacity: 0.7;
}
