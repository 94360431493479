.navColorBar {
    display: flex;
}

.navColorBar1 {
    height: 10px;
    background-color: #2e3192;
    width: 68%;
}

.navColorBar2 {
    height: 10px;
    background-color: #f7941e;
    width: 32%;
    position: relative;
}
.navColorBar2::after {
    position: absolute;
    content: "";
    height: 10px;
    background-color: #f7941e;
    width: 20px;
    left: -9px;
    bottom: 0;
    transform: skewX(-40deg);
}

.navBarRow {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 400;
    justify-content: flex-end;
    position: relative;
}

/* .navBar1 {
  height: 100px !important;
  align-items: start !important;
} */
.navBar {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    height: 100px;
    align-items: center;
    font-size: 27px;
    color: #fff;
    z-index: 5;
    width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 100;
}
.navBar:after {
    position: absolute;
    content: "";
    height: 100px;
    top: 0;
    right: -20%;
    left: -20%;
    background-image: linear-gradient(180deg, #313237 8%, transparent);
    z-index: -1;
}

.navBar:first-child a {
    z-index: 9999999 !important;
}

.navBarRow_primary {
    gap: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* // dont delete this font-size */
    font-size: 14px;
    line-height: 16px;
    height: 35px;
    position: relative;
}
.navBarRow {
    gap: 70px;
}
.navBarRow > div:nth-child(2) {
    display: flex;
}

.navRight {
    font-size: 16px;
    line-height: 22.4px;
}

.navBarRow_primary li,
.navRight li {
    text-decoration: none;
    list-style: none;
    color: var(--silver);
    display: flex;
    height: inherit;
}

.navBarRow_primary li a,
.navRight li a {
    text-decoration: none;
    list-style: none;
    color: #fff;
    position: relative;
    font-weight: bold;
}

.navRight {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    flex: 2;
    justify-content: flex-end;
    align-items: center;
}

.navRight > * + * {
    margin-left: 40px !important;
}
.BottomNavContainer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    text-align: center;
    background: #2d3192;
    padding: 12px 0 9px;
    position: relative;
}
.BottomNavContainer p {
    font-weight: bold;
    text-transform: capitalize;
}
.BottomNavContainer::after {
    position: absolute;
    z-index: 1;
    content: "";
    right: -15%;
    top: 0;
    height: 100%;
    width: 21%;
    background-color: #f7941d;
    transform: skewX(-45deg);
}
.BottomNavContainer img {
    width: 30px;
}
.BottomNavContainer p {
    color: #fff;
    font-size: 14px;
}
.dropdownContent {
    position: absolute;
    background-color: #000;
    filter: opacity(75%);
    /* opacity: 80% !important; */
    min-width: 215px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    top: 45px;
    right: -95px;
    border-radius: 10px;
    padding: 15px 20px;
}

.dropdownContent a {
    color: #fff;
    font-weight: bold;
    padding: 14px 0px;
    text-decoration: none;
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.438);
}
.dropdownContent > a:nth-child(1) {
    border-top: 0px solid;
}

.dropdownContent a:hover {
    background-color: #000;
    color: rgba(255, 255, 255, 0.966);
    filter: opacity(90%);
}
.MenuItem {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    /* padding: 14px 0px; */
}
.MenuItem a {
}
.MenuItem img {
    height: 16px;
    margin-left: 10px;
}
@media (max-width: 1400px) {
    .navBarRow_primary {
        font-size: 13px;
        height: 25px;
        gap: 60px;
    }
    .navBarRow {
        gap: 55px;
    }
}
@media (max-width: 1300px) {
    .dropdownContent {
        right: -80px;
    }
}
@media (max-width: 1200px) {
    .navBar {
        width: 90%;
        margin: 0 auto;
        /* padding: 0 20px 0 50px; */
    }

    .navRight > * + * {
        margin-left: 30px !important;
    }
}

@media (max-width: 991px) {
    .dropdownContent {
        top: 10px;
        right: 0px;
    }
    .navBar {
        width: 90%;
        margin: 0 auto;
        /* padding: 0 20px 0 20px; */
        grid-template-columns: 0fr 1fr;

        padding: 0;
    }

    .navBarRow {
        display: flex;
        width: 100%;
        align-items: center;
        font-weight: 400;
        /* justify-content: flex-end; */
    }

    .navBarRow_primary {
        flex: 10;
    }

    .navRight {
        width: 100%;
        justify-content: center;
        flex: 0;
    }
}
@media (max-width: 767px) {
    .dropdownContent {
        min-width: 200px;
    }
    .dropdownContent a {
        padding: 11px 0px;
    }
    .navBarRow_primary {
        grid-gap: 15px;
        white-space: pre;
    }
}
@media (max-width: 620px) {
    .BottomNavContainer img {
        width: 25px;
    }
    .BottomNavContainer p {
        font-size: 13px;
    }
    .BottomNavContainer {
        padding: 9px 0 6px;
    }
    .BottomNavContainer::after {
        transform: skewX(-35deg);
        right: -17%;
    }
}
@media (max-width: 460px) {
    .NavBar_MenuItem img {
        height: 12px;
        margin-left: 8px;
    }
    .navBarRow_primary {
        font-size: 13px;
        height: 23px;
    }
    .BottomNavContainer img {
        width: 22px;
        height: 24px;
    }
    .BottomNavContainer p {
        font-size: 12px;
    }
    .BottomNavContainer {
        padding: 7px 10px 6px 0;
    }
}
@media (max-width: 390px) {
    .BottomNavContainer img {
        width: 20px;
        height: 22px;
    }
    .BottomNavContainer {
        padding: 6px 10px 5px 0px;
    }
    .BottomNavContainer::after {
        right: -16%;
        transform: skewX(-35deg);
    }
}
