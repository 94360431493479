.CategoryItem_main {
    /* margin: 30px auto; */
    margin-bottom: -30px;
    /* width: 90%; */
    position: relative;
    z-index: 2;
}

.CategoryItem_main_info {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    justify-content: space-between;
    font-weight: bold;
}

.CategoryItem_main_link {
    height: 100%;
    border: none;
    color: var(--brandHover);
    text-decoration: none;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s ease;
}

.CategoryItem_main_link:hover {
    cursor: pointer;
    color: var(--brand);
}

.CategoryItem_main_info_title {
    color: var(--white);
    line-height: 28px;
    margin: 0px 10px;
    /* font-size: 32px; */
    font-weight: 300;
}
.sliderContainer {
    width: 92%;
    margin: 0 auto;
}
.CategoryItem_main_info_title,
.CategoryItem_main_info button {
    padding: 20px 0px 35px;
}

@media (max-width: 1023px) {
    .CategoryItem_main_info_title {
        /* font-size: 24px; */
    }
}

@media (max-width: 767px) {
    .CategoryItem_main_info_title {
        padding: 10px 0px 25px;
    }
}

@media (max-width: 550px) {
    .CategoryItem_main_info {
        width: 100% !important;
        margin: 0 auto !important;
    }

    .CategoryItem_main_link {
        /* display: none; */
        /* font-size: 12px; */
        position: relative;
        top: -7px;
    }
}
