.tosContainer {
    margin: 5% auto 2% auto;
    width: 80%;
    flex-grow: 1;
    color: white;
    line-height: 28px;
}

.padded {
    padding-left: 1%;
}
.padded li {
    padding: 5px 0;
}

.tosMainTitle_p {
    line-height: 28px;
}
.tosTitle {
    font-weight: 700;
    padding-bottom: 10px;
}
.tosMainTitle {
    padding-bottom: 10px;
    font-weight: 700;
}
.tosMainTitle_p a {
    color: var(--brandHover);
}

.tosContainer ol {
    counter-reset: item;
}
.tosContainer li {
    display: block;
}
.tosContainer li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
    margin-right: 5px;
}
