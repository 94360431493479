.rc-collapse {
  background-color: black !important;
  color: var(--white) !important;

  border: none !important;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  color: var(--white) !important;
  font-weight: 500;
}

.rc-collapse > .rc-collapse-item {
  border: none !important;
  padding: 10px 0;
}
.rc-collapse-content {
  background-color: var(--card) !important;
  color: var(--white) !important;
}
