.HeroCarousel > div {
    width: 100% !important;
    margin: 0 0 0 0% !important;
    height: 700px;
}

.HeroCarousel img {
    height: 700px;
    object-fit: cover;
}

.HeroCarousel {
    margin: -100px auto 0;
    position: relative;

    width: 100%;
}

.HeroCarousel::after {
    position: absolute;
    content: "";
    width: 900px;
    height: 900px;
    top: 0;
    right: -100px;
    bottom: 0;
    background: #000;
    z-index: -3;
}

.BannerImg:after {
    content: "";
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 8;
    position: absolute;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        80deg,
        rgba(0, 0, 0, 0.8830882694874825) 0%,
        rgba(42, 42, 62, 0.7010154403558299) 33%,
        rgba(34, 34, 55, 0.311659698058911) 52%,
        rgba(13, 13, 24, 0.03995101458552175) 72%,
        rgba(21, 26, 33, 0.1856092778908438) 92%,
        rgba(27, 37, 40, 0.6674019949776786) 100%
    );
}
.BannerImg::before {
    content: "";
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 8;
    position: absolute;
    background: rgb(5, 5, 5);
    background: linear-gradient(
        0deg,
        rgba(5, 5, 5, 0.8911939775910365) 0%,
        rgba(8, 8, 8, 0.1881127450980392) 20%,
        rgba(34, 34, 55, 0) 45%,
        rgba(13, 13, 24, 0) 72%,
        rgba(21, 26, 33, 0) 92%,
        rgba(247, 250, 251, 0) 100%
    );
}

.HeroContents {
    max-width: 540px;
    position: absolute;
    bottom: 26%;
    left: 5%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    z-index: 100;
}

.HeroContents h3 {
    font-size: 34px;
    color: #fff;
    padding: 0;
    /* margin-bottom: 15px; */
}

.HeroContents h6 {
    font-size: 14px;
    color: #fff;
    border: 1px solid #fff;
    margin-right: 5px;
    padding: 8px 12px;
    font-weight: 400;
    width: auto;
    text-align: center;
}

.HeroContents h4 {
    font-size: 22px;
    color: #fff;

    /* padding: 10px 0; */
    margin: 15px 0;
    font-weight: 400;
}
.durationYear {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 520px;
    padding: 5px 0;

    margin: 20px 0;
}
.HeroContents p {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    font-weight: 300;
}

.HeroContents .HeroText {
    display: flex;
    align-items: center;
}
.heroContainer {
    position: relative;
}
.heroContainer svg {
    position: absolute;
    bottom: 20%;
    right: 11%;
    z-index: 10;
}
@media (max-width: 1930px) {
    .HeroCarousel {
        min-height: 780px;
    }
    .HeroCarousel img {
        height: calc(100vh - 200px);
        min-height: 780px;
    }

    .HeroCarousel > div {
        height: calc(100vh - 200px);
        /* min-height: 880px; */
    }
}
@media (max-width: 1700px) {
    .HeroCarousel img {
        height: calc(100vh - 160px);
    }

    .HeroCarousel > div {
        height: calc(100vh - 160px);
    }
}
@media (max-width: 1500px) {
    .HeroContents h3 {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .HeroContents h6 {
        font-size: 13px;
    }

    .HeroContents h4 {
        font-size: 18px;
        margin: 10px 0;
        /* width: 440px; */
    }
    .durationYear {
        width: 440px;
    }

    .HeroContents {
        max-width: 480px;
    }

    .HeroContents p {
        font-size: 13px;
        line-height: 19px;
    }
    .HeroCarousel {
        min-height: 568px;
    }
    .HeroCarousel img {
        height: calc(100vh - 165px);
        min-height: 568px;
    }

    .HeroCarousel > div {
        height: calc(100vh - 165px);
    }
}

@media (max-width: 1200px) {
    .HeroContents h3 {
        font-size: 28px;
    }

    .HeroContents h6 {
        font-size: 12px;
        padding: 6px;
    }

    .HeroContents h4 {
        font-size: 16px;
        /* width: 400px; */
    }
    .durationYear {
        width: 400px;
    }

    .HeroContents {
        max-width: 440px;
    }

    .HeroContents p {
        font-size: 13px;
        line-height: 19px;
    }

    .HeroCarousel img {
        height: calc(100vh - 150px);
    }

    .HeroCarousel > div {
        height: calc(100vh - 150px);
    }
}

@media (max-width: 1023px) {
    .heroContainer svg {
        width: 80px;
        bottom: 23%;
    }
    .HeroContents {
        bottom: 30%;
    }

    .HeroCarousel img {
        height: 600px;
    }

    .HeroCarousel > div {
        height: 600px;
    }
    .HeroCarousel img {
        height: calc(100vh - 140px);
    }

    .HeroCarousel > div {
        height: calc(100vh - 140px);
    }
}
@media (max-width: 949px) {
    .HeroCarousel img {
        height: calc(100vh - 155px);
    }

    .HeroCarousel > div {
        height: calc(100vh - 155px);
    }
}

@media (max-width: 767px) {
    .heroContainer svg {
        width: 60px;
    }
    .HeroContents p {
        line-height: 17px;
        font-size: 11px;
    }

    .HeroContents h3 {
        font-size: 28px;
    }

    .HeroContents {
        bottom: 35%;
        max-width: 460px;
    }

    .HeroContents h6 {
        margin-right: 3px;
        padding: 5px;
    }

    .HeroCarousel img {
        height: 550px;
    }

    .HeroCarousel > div {
        height: 500px;
    }
}

@media (max-width: 580px) {
    .HeroContents p {
        line-height: 14px;
        font-size: 10px;
        display: none;
    }
    .durationYear {
        display: none;
    }
    .HeroContents {
        max-width: 95%;
    }
    .HeroContents h3 {
        font-size: 18px;
    }
    .HeroContents h4 {
        width: 100%;
        font-size: 13px;
        padding: 5px 0;
    }

    .HeroCarousel img {
        height: 530px;
    }

    .HeroCarousel > div {
        height: 530px;
    }
}
@media (max-width: 360px) {
    .HeroContents {
        max-width: 95%;
    }

    .HeroContents h4 {
        /* max-width: 275px; */
        letter-spacing: 0;
    }

    .durationYear {
        max-width: 275px;
    }

    .HeroContents h6 {
        margin-right: 3px;
        padding: 4px;
    }

    .HeroContents {
        font-size: 13px;
    }
}
