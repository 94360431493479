.faqContainer {
  margin: 5% auto 30px auto;
  width: 95%;
  flex-grow: 1;
  color: var(--white);
  /* font-size: var(--font20); */
}

.faqTitle {
  font-size: var(--font20);
  margin: 50px 0;
}

.faqsubTitle {
  /* font-size: var(--font18); */
  margin: 30px 0;
  color: var(--silver);
}
