.details_description_container {
    width: 92%;
    margin: 0 auto;
    background-color: #000;
    overflow: hidden;
    padding: 25px 0;
    box-sizing: border-box;
    color: white;
    margin-top: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.details_description_container > * + * {
    margin: 23px 0;
}
.detailsDescriptionTitleContainer {
    display: flex;
    flex-direction: row;
    /* border-bottom: 1px solid var(--brand); */
    /* padding: 10px; */
    overflow: hidden;
    align-items: center;
}
.details_description_title_name {
    /* text-transform: uppercase; */
    font-weight: 300;
    color: var(--white);
    line-height: 33.6px;
    font-size: 36px;
    margin-bottom: 15px;
}

.details_description_title_left {
    flex: 2;
    margin: 0;
}
.details_description_title_right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.HeroText {
    display: flex;
    align-items: center;
}
.HeroContents {
    display: flex;
    align-items: flex-start;
}
.RightContent {
    /* width: 55%; */
    flex: 1;
    margin-top: 20px;
}
.LeftContent {
    /* width: 43%; */
    flex: 1.2;
}
.HeroContents h6 {
    font-size: 20px;
    color: #fff;
    border: 1px solid #fff;
    margin-right: 5px;
    padding: 8px 16px;
    font-weight: 300;
    width: 100%;
    text-align: center;
}
.durationYear {
    display: flex;
    /* justify-content: flex-start;
    align-items: center; */
    margin-bottom: 5px;
}
.durationYearItem {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: flex;
    gap: 20px;
    padding: 15px 0;
}

.HeroContents h4 {
    font-size: 20px;
    color: #fff;
    /* border-top: 1px solid #fff; */
    /* border-bottom: 1px solid #fff; */
    padding: 15px 0;
    margin: 0px 0 15px;
    font-weight: 300;
    width: 75%;
}

.HeroContents p {
    font-size: 18px;
    color: #fff;
    line-height: 22px;
    font-weight: 300;
}
.seperator {
    color: white;
    font-weight: 800 !important;
}

.HeroContents .HeroText {
    display: flex;
    align-items: center;
    width: 86%;
    margin-top: 5px;
}
@media (max-width: 950px) {
    .details_description_container {
        width: 90%;
    }
}
@media (max-width: 1600px) {
    .details_description_title_name {
        margin-bottom: 5px;
    }
    .details_description_title_name {
        font-size: 28px;
    }
    .HeroContents h4 {
        font-size: 20px;
        padding: 13px 0;
        width: 75%;
    }
    .HeroContents h6 {
        font-size: 18px;
        padding: 9px 12px;
    }
}
@media (max-width: 1400px) {
    .details_description_title_name {
        font-size: 24px;
    }

    .HeroContents h6 {
        font-size: 16px;
    }

    .HeroContents h4 {
        font-size: 18px;
        margin: 5px 0 10px;
        width: 88%;
    }
    .HeroContents p {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 1200px) {
    .details_description_title_name {
        font-size: 22px;
    }
    .HeroContents h6 {
        font-size: 14px;
        padding: 10px;
    }

    .HeroContents h4 {
        font-size: 14px;
    }

    .HeroContents p {
        font-size: 14px;
        line-height: 17px;
    }
}

@media (max-width: 1023px) {
    .HeroContents .HeroText {
        width: 80%;
    }
    .durationYearItem {
        border-top: 0;
        border-bottom: 0;

        padding: 15px 0;
        margin: 10px 0;
    }

    .HeroContents {
        flex-direction: column;
    }
    .HeroContents .RightContent {
        width: 100%;
    }
    .HeroContents .LeftContent {
        width: 100%;
    }
    .HeroText {
        width: 80%;
    }
    .HeroContents h4 {
        padding: 10px 0;
        margin: 15px 0;
    }
}
@media (max-width: 949px) {
}

@media (max-width: 767px) {
    .HeroContents p {
        line-height: 17px;
    }
    .HeroContents h4 {
        width: 84%;
    }
    .details_description_title_name {
        font-size: 20px;
    }
    .HeroContents h6 {
        margin-right: 3px;
        padding: 7px;
        font-size: 13px;
    }
}

@media (max-width: 580px) {
    .HeroContents .HeroText {
        width: 100%;
    }
    .details_description_title_name {
        font-size: 18px;
    }
    .HeroContents h4 {
        width: auto;
        font-size: 12px;
        padding: 7px 0;
    }
    .HeroContents h6 {
        font-size: 12px;
    }
    .HeroContents p {
        font-size: 12px;
        line-height: 14px;
    }
}
@media (max-width: 550px) {
    .details_description_container {
        width: 95%;
        padding: 15px 6px;
        margin-top: 0px;
    }
}
@media (max-width: 365px) {
    .details_description_title_name {
        font-size: 17px;
    }
    .HeroContents h4 {
        letter-spacing: 0;
        font-size: 12px;
    }

    .HeroContents h6 {
        margin-right: 3px;
        padding: 4px 8px;
    }
}
