.selected {
    color: var(--brand) !important;
}

.navBarBTN:hover {
    text-decoration: underline;
    cursor: pointer;
}

.categories_info {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.navBarSiteLogo {
    height: 50px;
    justify-content: center;
    align-items: center;
}

.navBarSiteLogo:hover {
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    /*background: linear-gradient(to right, #000000, #171d31, #000000);*/
    /* background: #12161d; */
    background-color: #000;
    /*
  background-image: linear-gradient(to right, #000000, #171d31, #000000);
  background-image: -moz-linear-gradient(to right, #000000, #171d31, #000000);*/
    min-height: 100vh;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    position: relative !important;
}
.MobileNav {
    display: none;
    position: fixed;
    /* position: absolute; */
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1005;
}
.item {
    transition: all 0.3s ease-in-out;
}

.chosenCategoryGrid {
    flex-grow: 1;
    height: 100%;
}

.categoryItemTitle {
    background-color: #646464;
    color: white;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.7rem 0.5rem 0.7rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    text-align: center;
    overflow: hidden;
    width: 15rem;
}

.categoryItemImg {
    object-fit: contain;
    display: block;
    max-height: 24rem;
    max-width: 16rem;
    /*min-height: 24rem;*/
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

/*
.categoryItem:hover {
  transform: scale(1.1);
}
*/

.detailsUpper {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 25%;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 450px; */
}

.detailsUpperBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100% 450px; */
}

.detailsUpperSmall {
    width: 80%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.detailsUpperCover {
    width: 100%;
    vertical-align: middle;
}

.detailsPlayContainer {
    background-color: var(--brand);

    text-decoration: none;
    margin-bottom: 20px;
    color: var(--white);
    border: none;
    text-transform: uppercase;
    border-radius: var(--font12);
    line-height: 130%;
    display: flex;
    align-items: center;
    gap: 8px;
}

.detailsPlayContainer:hover .svg-play {
    fill: var(--brand);
}

.detailsPlayContainer:hover {
    cursor: pointer;
    background: var(--brandHover);
    color: var(--brand);
}

.svg-play {
    fill: white;
}

.detailsUpperCoverContainer {
    display: inline-block;
    width: 20%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.categoryContent,
.similiarCategoryContent {
    margin: 0 auto;
    width: 95%;
    padding: 0 1rem 0 1rem;
}

.categoryContent::-webkit-scrollbar {
    display: none;
}

.banners {
    margin: 0 auto;
    width: 95%;
    padding: 0 1rem 0 1rem;
}

.catArrow {
    color: white;
    width: 2rem;
    position: relative;
    z-index: 100;
    align-self: center;
    cursor: pointer;
    font-size: 3rem;
    transition: all 0.3s ease-in-out;
}

.catArrow:hover {
    background-color: rgba(20, 20, 20, 5);
    -moz-box-shadow: 0 0 1rem #fff;
    -webkit-box-shadow: 0 0 1rem #fff;
    box-shadow: 0px 0px 1rem #fff;
    border-radius: 1rem;
}

.arrowRight {
    margin-left: auto;
    margin-right: 1rem;
}

.arrowLeft {
    margin-left: 1rem;
}

.categoryTitleContainer,
.similiarCategoryTitleContainer {
    margin: 1.5rem 0 1.5rem 0;
    color: #fcca13;
    font-family: "Brandon", Tahoma, sans-serif;
    font-size: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.similiarContainer {
    margin-top: 1.5rem;
}

.categoryTitle {
    text-align: center;
    cursor: default;
}

.titleHover:hover {
    cursor: pointer;
    text-decoration: underline;
}

.faqContainer {
    margin: 0 auto 0 auto;
    width: 80%;
    color: white;
    font-size: 16px;
    flex-grow: 1;
}

.playVideoContainer {
    /*
 margin: 0 auto 0 auto;
  width: 100%;
  color: white;
  font-size: 16px;
  flex-grow: 1;
  */

    overflow: hidden;
    /*
  position: relative;
  padding-top: 56.25%;
  */
}

.maxContainer {
    display: flex;
    /* max-height: 100vh; */
    /* height: 100vh; */
    width: 92%;
    background-color: black;
    padding: 0 10%;
    margin: 0 auto;
}

.maxContainerPrimary {
    width: 100%;
    background: #000;
    position: relative;
    /* padding: 10px 0; */
}

.embedButton {
    position: absolute;
    /* // should be 40px all the time */
    bottom: 40px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--brand);
    /* padding: 0px 10px; */
    color: var(--silver);
    padding: 10px;
    opacity: 0;
}

.embedButton:hover {
    cursor: pointer;
}

.embedButton > * + * {
    margin-left: 10px;
}

.maxContainerEmbed {
    width: 100%;
    margin: 0 auto;
    max-height: 100vh;
    /* overflow: hidden; */
}

.maxContainerPrimary:hover .embedButton {
    opacity: 1;
}

/*
.radiantPlayer {
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}

.radiantPlayer .rmp-control-bar {
  margin-top: -200px;
}
*/

.searchColumn {
    display: flex;
    flex-direction: column;
    margin-right: 2%;
}

.searchButton {
    height: 50px;
}

.searchField {
    position: relative;
    padding: 17px 20px 17px 15px;
    outline: none;
    color: var(--background);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
    max-height: 50px;
    min-height: 50px;
    width: 400px;
}

.searchRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 1%;
    align-items: center;

    justify-content: space-between;
}

.searchCheckbox {
    margin-right: 0.25rem;
}

.searchCheckboxLabel {
    margin-right: 1%;
}

.resultsAmount {
    margin-left: 10%;
    margin-top: 1%;
    font-size: 16px;
    margin-right: 20%;
}

.searchResultBTN {
    background: var(--silver);
}

/* .active {
  background: var(--brand) !important;
} */
.searchResultBTN:hover {
    background: var(--brandHover);
}

.searchLabel {
    margin-bottom: 0.25rem;
    height: 18px;
}

.searchSelectCategory {
    padding: 0.25rem;
}

.searchContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;
    width: 80%;
    margin: 0 auto;
}

.searchTitle {
    font-size: 28px;
    margin-bottom: 2rem;
}

.row {
    display: flex;
    flex-direction: row;
}

.slick-track {
    display: flex !important;
    gap: 10px;
    background: #000;
}

.videoContainer {
    padding-top: 5%;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.details-description-container {
    width: 80%;
    margin: 0 auto;
    background-color: #1e2228;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 30px;
}

.detailsDescriptionTitleContainer {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #fcca13;
    padding: 10px;
    overflow: hidden;
}

.details-description-title-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    float: left;
}

.details-description-title-right {
    max-width: 30%;
    margin-left: auto;
}

.details-description-title-name {
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: auto;
}

.folderNamesList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
}

.folderName {
    color: white;
    font-size: 20px;
    margin-right: 0.5rem;
}

.ratingElement {
    width: 50px;
}

.details-desription-mobile-cover {
    display: none;
}

.details-description-info-container {
    /* margin: 20px auto; */
    display: flex;
}

.detailsContainer {
    /* font-family: 'Brandon', Tahoma, sans-serif; */
}

.detailsInfoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.infoDataContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: var(--silver);
}

.dataTitle {
    font-weight: bold;
    margin-right: auto;
}

.dataValue {
    margin-right: auto;
    padding: 10px 0px;
}

.shareBTN {
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin-right: 5px;
}

.details-share {
    display: flex;
    padding-top: 35px;
    flex-wrap: wrap;
    width: 100%;
}

.isClicked {
    max-height: 100% !important;
    height: 100% !important;
    /* overflow: scroll; */
}

.detailsDescriptionContainer {
    flex: 3;
}

.svg-triangle {
    width: 26px;
    height: 20px;
    transform: rotateZ(180deg);
}

.categoryGridContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.resultsGrid {
    width: 100%;
    margin-top: 1.5%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.categoryItem {
    /* max-width: 17rem; */
    transition: all 0.3s ease-in-out;
    margin: 0.5rem 0.5rem;
}

.categoryItemPlaceholder {
    /* max-width: 17rem; */
    transition: all 0.3s ease-in-out;
    margin: 0.5rem 0.25rem;
    display: flex;
    justify-content: center;
}

.promoContainer {
    margin: 0 auto;
    overflow: hidden;
}

.promoItem {
    box-sizing: border-box;
    width: 33.333%;
    padding: 0.2rem;
}

.promoItemImg {
    width: 100%;
    cursor: pointer;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.bannerItem {
    width: 100%;
    cursor: pointer;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.categoryItem:hover {
    cursor: pointer;
}

.footerLine {
    border-top: 2px solid #f6c917;
    margin: 20px 0;
}

.bottomBar {
    width: 100%;
    height: 120px;
    padding: 0 10% 1% 10%;
    display: table;
    color: white;
    z-index: 5;
}

.footerInfo {
    padding-left: 20px;
    display: inline-block;
}

.footerInfoName {
    font-size: 18px;
    margin-bottom: 15px;
}

.footerInfoAddress {
    font-size: 12px;
    float: left;
    margin-right: 180px;
}

.footerInfoPhoneEmail {
    display: inline-block;
    font-size: 12px;
}

.footerTos {
    font-size: 12px;
    float: right;
    padding-right: 20px;
}

.footerTos:hover {
    text-decoration: underline;
    cursor: pointer;
}

.tosContainer {
    margin: 0 auto 0 auto;
    width: 80%;
    flex-grow: 1;
    color: white;
    font-size: 12px;
}

.SVOD-container,
.TVOD-container {
    margin: 0.5rem;
    width: 10rem;
    height: 10rem;
    background-color: #fcca13;
    text-align: center;
}

.SVOD-container:hover,
.TVOD-container:hover {
    cursor: pointer;
}

.main-container {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 70.5%;
    justify-content: center;
}

.categoriesDropdown {
    flex-direction: column;
    position: absolute;
    max-height: 15rem;
    background: var(--card);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* right: 0%; */
    border-radius: 0.25rem;
    z-index: 100000000000;
    color: var(--silver);
    /* width: 187px; */
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
}

.categoriesDropdown > * + * {
    margin-top: 10px;
}

.categoriesDropdown a,
.categoriesDropdown li {
    text-decoration: none;
    color: var(--silver);
    cursor: pointer;
    /* // dont remove font-size */
    font-size: 14px;
}

.categoriesDropdown a:hover,
.categoriesDropdown li:hover {
    text-decoration: none;
    color: var(--brandHover);
    /* background-color: var(--brandHover); */
}

.categoriesBar {
    display: flex;
    margin-top: 1rem;
    width: 93%;
    margin-left: auto;
    margin-right: auto;
}

.categoriesButtonContainer {
    display: inline-block;
    position: relative;
    color: white;
    font-size: 24px;
}

.categoriesButton {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
}

.svg-chevron {
    margin-left: 0.5rem;
}

.dropdownOption {
    padding: 0.25rem 2rem 0.25rem 1.5rem;
    text-align: start;
    width: 100%;
    cursor: pointer;
    font: 82.5%/1.8 Arial, Helvetica, Verdana, sans-serif;
}

.dropdownOption:hover {
    background: #313131;
}

.loginContainer,
.signUpContainer {
    cursor: pointer;
    display: flex;
}

.loginContainer > * + * {
    margin-left: 10px;
}

.loginBTN {
    color: var(--brandHover);
    text-decoration: none;
}

.loginBTN:hover {
    color: var(--brandHover);
    text-decoration: none;
}

.active {
    color: var(--brand);
}

.modal-background {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.5);
    cursor: pointer;
    position: absolute;
    z-index: 110;
}

.loginFormContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.75rem auto;
    position: relative;
    background-color: #3d3d3d;
    border-radius: 0.25rem;
    padding: 1rem 2rem 1rem 2rem;
    max-width: 30rem;
    max-height: 20rem;
    z-index: 120;
    cursor: default;
}

#form_id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.loginFormInput {
    width: 100%;
    margin: 0.25rem 0 0.25rem 0;
}

.signUpBarInput {
    padding-left: 0.5rem;
    width: 100%;
    margin-right: 0.25rem;
}

.signUpBar {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    color: white;
    align-items: center;
}

.signUpBar2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    color: white;
    align-items: center;
    padding: 20px auto;
}

.signUpBackground {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20rem;
    width: 100%;
    z-index: 1;
    padding: 0;
    margin: 0;
}

.signUpWelcomeText {
    font-size: 24px;
    width: 33.3%;
    z-index: 2;
    margin-top: 2rem;
}

.signUpWelcomeText2 {
    margin-left: auto;
    margin-right: auto;
    font-size: 48px;
    z-index: 2;
    margin-top: 2rem;
}

.signUpWelcomeText3 {
    font-size: 28px;
    z-index: 2;
    margin-top: 2rem;
}

.signUpRow {
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    z-index: 2;
}

.loginMessage {
    height: 4rem;
}

.loginFormTOS {
    margin: 0.5rem 0 0.5rem 0;
    font-size: 18px;
    font-weight: normal;
}

.loginViewBackground {
    background-size: cover;
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    /*position: absolute;*/
    width: 100%;
    z-index: 1;
}

.loginViewContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.loginFormButtons {
    display: flex;
    flex-direction: row;
}

.loginFormBTN-background {
    background: #3d3d3d;
}

.loginFormBTN {
    background: lightgray;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0 0.5rem 0 0;
    margin-left: 10%;
}

.loginFormBTN:hover {
    background: transparent;
}

/*
.signUpBarBTN-background {
  background: #3d3d3d;
}
*/

.signUpBarBTN {
    background: rgb(125, 221, 14);
    cursor: pointer;
    padding: 0.5rem;
    margin: 0 0.5rem 0 0;
    border-radius: 0.5rem;
    font-weight: 300;
    border: none;
    font-size: 38px;
}

.signUpBarInfoMessage {
    background: #f6c917;
    display: flex;
    font-size: 18px;
    z-index: 5;
    color: black;
    margin-top: 0.25rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
}

.signUpBarBTN:hover {
    background: rgb(230, 229, 229);
    /* background: transparent;*/
}

.signUpFormBTN {
    background: lightgray;
    cursor: pointer;
    padding: 0.5rem;
    width: 80%;
    margin-left: 10%;
}

.signUpFormBTN:hover {
    background: rgb(230, 229, 229);
    /* background: transparent;*/
}

.signUpForm {
    display: flex;
    flex-direction: column;
    width: 25%;
    color: white;
}

.stepsContainer {
    font-size: 20px;
}

.signUpFormInput {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.signUpStep1 {
    display: flex;
    flex-direction: column;
}

.signUpStepText {
    font-size: 20px;
    margin-bottom: 0.5rem;
    width: 80%;
    margin-left: 10%;
}

.signUpStepTitle {
    font-size: 22px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.loginFormTitle {
    font-size: 22px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

#userDetails-form {
    display: flex;
    flex-direction: column;
}

.profile-container {
    width: 80%;
    flex-grow: 1;
    padding-top: 5%;
    margin: auto;
    display: flex;
    flex-direction: row;
    color: white;
}

.profile-menu-option {
    font-size: 18px;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
}

.profile-menu-option:hover {
    cursor: pointer;
    box-shadow: 0 0 11px rgba(241, 241, 241, 0.2);
}

.profile-option-view {
    color: black;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.profile-details-row {
    display: flex;
    align-items: center;
    margin: 0.5rem auto 0.5rem auto;
}

.inputColumn {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;
}

.profileFormInput {
    width: 30rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.profileFormBTN {
    align-self: center;
    width: 5rem;
    margin-left: 10%;
}

.loginFirstMessage {
    font-size: 18px;
    margin: auto;
}

/* Guest: Ανδρέας Χαραλάμπους, Ιδρυτής της Capsule Skateboards */

.profileMessage {
    margin: 2rem auto;
}

.series-seasons-container {
    box-sizing: border-box;
    /* width: 80%; */
    margin: 0 auto;
    flex-wrap: wrap;

    /* padding: 20px; */
}

.seasonsAndEpisodesContainer {
    width: 92%;
    margin: 0 auto;
}

.seasonsAndEpisodesContainer > * {
    margin-top: 30px !important;
}

.series-seasons-header {
    /* font-family: 'Brandon', Tahoma, sans-serif; */
    color: white;
    padding: 20px 0;
}

.series-seasons-numbers {
    display: flex;
    background: #f7941d;
}

.series-seasons-numbers > a {
    margin-right: 5px;
}

.seasonNumber {
    background-color: #2e3192;
    color: var(--white);
    /* border: 1px solid var(--white); */
    display: flex;
    /* padding: 10px; */
    cursor: pointer;
    text-decoration: none;
    /* width: 181px; */
    /* margin-right: 16px; */
    height: 45px;
    display: flex;
    justify-self: center;
    align-items: center;
    border-radius: inherit;
    position: relative;
}
.seasonNumber:disabled {
    background-color: #2e3192;
    color: var(--white);
}
.seasonNumber:hover {
    color: var(--brandHover);
}

.series-episode-list {
    /* width: 80%; */
    margin: 0 auto;
    display: grid;
    grid-gap: 12px;
    gap: 13px;
    grid-template-columns: repeat(5, 1fr);
}

.episodeDetailsMiddle {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    /* margin-left: 15px; */
    margin-top: 5px;
}

.episodeDetailsMiddle:hover {
    cursor: pointer;
}

.episodeDetailsMiddleTop {
    display: flex;
    flex-direction: row;
    color: var(--white);
    margin: 5px 0 0;
    line-height: 20.8px;
    font-weight: bold;
}

.episodeDetailsMiddleBottom {
    margin-top: 5px;
}

.episodeDetailsLeft {
    display: block;
    margin: 0;
    padding: 0;
}

.episodeDetailsRight {
    display: flex;
    width: 11%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.episodeDetailsItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    background: #000;
    color: var(--blueSilver) !important;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
}

.info-duration {
    margin-left: 20px;
}

.episode-list-img {
    margin: 0 auto;
    display: block;
    padding: 0;
    vertical-align: middle;
    /* height: 310px; */
    width: 95%;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 16/9;
}
.episode-list-img span {
    height: 100% !important;
}
.episode-list-img:hover {
    cursor: pointer;
}

.showPlayerBar {
    display: flex;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.icon-play {
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.icon-play:hover {
    color: var(--brandHover);
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;

    transform: translate(-50%, -50%);
}

.episode-list-description {
    color: white;
    /* font-family: 'Brandon', sans-serif; */
    font-size: 16px;
}

.thumbnail-container {
    /* position: relative; */
    box-sizing: border-box;
}

.informPlaceholder {
    font-size: 28px;
    color: white;
    text-align: center;
    height: 70%;
}

.play {
    background-image: url("../src/images/button_play_inactive.png");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.play:hover {
    background-image: url("../src/images/button_play_active.png");
}

.langFlag {
    margin-right: 0.5rem;
    width: 40px;
    max-width: 40px;
    cursor: pointer;
}

.languageSelect {
    margin-left: auto;
    padding-top: 10px;
}

.categoriesContainer {
    padding-bottom: 50px;
    /* width: 95%; */
    margin: 0 auto;
}

.bannerContainer {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
}

.latest-most-container {
    padding-bottom: 50px;
}

.categoriesContainermain {
    width: 100% !important;
    padding: 0 4% !important;
    background-color: #000;
    margin-top: -150px;
}

/* @media (max-width: 1700px) {
  .episode-list-img {
    height: 280px;
}
}
@media (max-width: 1600px) {
  .episode-list-img {
    height: 260px;
}
}
@media (max-width: 1500px) {
  .episode-list-img {
    height: 250px;
}
} */
@media (max-width: 1400px) {
    .activeSeason::after,
    .seasonNumber:hover::after {
        width: 47%;
        height: 3px;
        bottom: 9px;
        right: 27%;
    }
    .seasonNumber {
        padding: 10px 30px;
        font-size: 12px;
        height: 40px;
    }
    .series-seasons-container > div > .seasonNumber:last-child:before {
        right: -25%;
        transform: skewX(-29deg);
    }
    .series-episode-list {
        gap: 10px;
        grid-template-columns: repeat(5, 1fr);
    }
    /* .episode-list-img {
    height: 270px;
} */
}
/* @media (max-width: 1300px) {
  .episode-list-img {
    height: 260px;
}
} */

@media (max-width: 1240px) {
    .series-episode-list {
        grid-template-columns: repeat(4, 1fr);
    }
    /* .episode-list-img {
    height: 300px;
} */
    .maxContainerPrimary {
        width: 100%;
        background: black;
        /* padding: 10px 0; */
    }

    .maxContainer {
        width: 100%;
        padding: 0;
    }

    .maxContainerEmbed .rmp-control-bar {
        bottom: 0px !important;
    }
}
/* @media (max-width: 1100px) {
  .episode-list-img {
    height: 260px;
}
} */
@media (max-width: 950px) {
    /* .episode-list-img {
    height: 240px;
} */
    .series-seasons-container {
        box-sizing: border-box;
        /* width: 90%; */
        margin: 0 auto;
        /* padding: 20px; */
    }

    .series-episode-list {
        /* width: 90%; */
    }
}
@media (max-width: 900px) {
    .series-episode-list {
        grid-template-columns: repeat(3, 1fr);
    }
    /* .episode-list-img {
  height: 280px;
} */
}
@media (max-width: 800px) {
    /* .episode-list-img {
  height: 260px;
} */
}
@media (max-width: 767px) {
    .MobileNav {
        display: block;
    }
}
@media (max-width: 750px) {
    /* .episode-list-img {
    height: 220px;
  } */
    .bannerContainer {
        padding-bottom: 20px !important;
    }

    .categoriesContainer {
        /* max-width: 95%;
    margin: 0 5% 0 0; */
        padding-bottom: 0;
    }

    .series-seasons-container {
        box-sizing: border-box;
        width: 100%;
        margin: 0 auto;
        /* padding: 20px; */
    }

    .series-episode-list {
        width: 95%;
    }

    .embedButton {
        /* padding: 0px;
    gap: 5px;
    bottom: 35px; */
        padding: 0 0 0 5px;
    }
}
@media (max-width: 640px) {
    .series-seasons-container > div > button:last-child:before {
        right: -41% !important;
        transform: skewX(-22deg);
    }
    .activeSeason::after,
    .seasonNumber:hover::after {
        bottom: 7px;
        right: 19%;
        width: 62%;
        height: 2px;
    }
    .series-seasons-container > div > button:last-child::after,
    .series-seasons-container > div > button:last-child:hover::after {
        right: 36%;
        width: 50%;
        top: 22px;
    }
    .seasonNumber {
        padding: 10px 12px;
        font-size: 10px;
        height: 30px;
    }
    .series-seasons-container > div > button:last-child {
        padding-right: 30px;
    }

    .series-episode-list {
        grid-template-columns: repeat(2, 1fr);
    }
    /* .episode-list-img {
  height: 300px;
} */
}
@media (max-width: 550px) {
    /* .episode-list-img {
    height: 270px;
  } */
    .detailsUpperBackground {
        background-size: 100% 116px;
    }

    .episodeDetailsMiddleTop {
        margin: 0;
    }

    .detailsUpper {
        /* height: 116px; */
    }

    .navBarSiteLogo {
        height: 38px;
    }

    .series-seasons-container {
        width: 95%;
        padding: 0;
        margin-top: 15px !important;
    }

    .episodeDetailsMiddle {
        margin-left: 5px;
    }

    .episodeDetailsMiddleTop {
        font-size: 12px;
        line-height: 16px !important;
    }
    .NowPlay {
        font-size: 12px;
    }
    .seasonsAndEpisodesContainer {
        width: 100%;
    }
}
/* @media (max-width: 480px) {
  .episode-list-img {
    height: 220px;
  }
} */
@media (max-width: 420px) {
    .series-seasons-container > div > button:last-child:before {
        display: none;
    }
    .series-seasons-numbers {
        background: #2e3192;
    }
    /* .episode-list-img {
    height: 210px;
  } */
}
/* @media (max-width: 380px) {
  .episode-list-img {
    height: 180px;
  }
} */

/* custom scroll */

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
